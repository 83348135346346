var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},_vm._l((_vm.items),function(item,idx){return (!item.children.length)?_c('router-link',{key:idx,attrs:{"to":{name: item.routeName}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t(("label." + (item.name))))+" ")])])])]}}],null,true)}):_c('li',{staticClass:"menu-item menu-item-submenu menu-item-rel",class:{ 'menu-item-active': _vm.hasActiveChildren(item.name) },attrs:{"data-menu-submenu-mode":"accordion","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"javascript:;"}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(("label." + (item.name)))))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-left"},[_c('ul',{staticClass:"menu-subnav"},_vm._l((item.children),function(child,idxx){return _c('router-link',{key:idx+''+idxx,attrs:{"to":{name: child.routeName}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(("label." + (child.name)))))])])])]}}],null,true)})}),1)])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }