<template>
  <ul class="menu-nav">
    <router-link
      v-if="!item.children.length"
      v-for="(item, idx) in items"
      :key="idx"
      :to="{name: item.routeName}"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ $t(`label.${item.name}`) }} </span>
        </a>
      </li>
    </router-link>

    <li
      v-else
      class="menu-item menu-item-submenu menu-item-rel"
      data-menu-submenu-mode="accordion"
      data-menu-toggle="click"
      v-bind:class="{ 'menu-item-active': hasActiveChildren(item.name) }"
    >
      <a href="javascript:;" class="menu-link menu-toggle">
        <span class="menu-text">{{ $t(`label.${item.name}`) }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-left">
        <ul class="menu-subnav">
          <router-link
            v-for="(child, idxx) in item.children"
            :key="idx+''+idxx"
            :to="{name: child.routeName}"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li class="menu-item" aria-haspopup="true" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t(`label.${child.name}`) }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'KTMenu',
  data: () => ({
    items: [
      {name: 'dashboard', routeName: 'dashboard', children: []},
      {name: 'users', routeName: 'usersList', children: []},
      {name: 'roles', routeName: 'rolesList', children: []},
      {name: 'complexes', routeName: 'complexesList', children: []},
      {name: 'teams', routeName: 'teamsList', children: []},
      {name: 'tickets', routeName: null, children: [
          {name: 'tickets', routeName: 'ticketsList'},
          {name: 'ticket_types', routeName: 'ticketTypesList'},
        ]},
      {name: 'tasks', routeName: 'tasksList', children: []},
      {name: 'companies', routeName: 'companiesList', children: []},
      {name: 'transformers', routeName: 'transformersList', children: []},
      {name: 'locks_report', routeName: 'locksReport', children: []},
      {name: 'gateway', routeName: 'gatewaysList', children: []},
    ],
  }),
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    }
  }
};
</script>
